import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Constant.css';

export default function ConstantModal({ constant, open, handleClose, handleSave, handleDelete }) {
  const [newConstant, setNewConstant] = useState(constant);

  const handleChange = (event) => {
    setNewConstant({ ...newConstant, [event.target.name]: event.target.value });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="ConstantModal">
          <h2>Edit Constant</h2>
          <TextField
            label="Title"
            name="titleVc"
            value={newConstant.titleVc}
            onChange={handleChange}
            margin="normal"
            style={{ verticalAlign: 'unset', padding: '3px' }}
          />
          <TextField
            label="Description"
            name="descriptionTx"
            value={newConstant.descriptionTx}
            onChange={handleChange}
            margin="normal"
            style={{ verticalAlign: 'unset', padding: '3px' }}
          />
          <TextField
            label="Value"
            name="valueTx"
            value={newConstant.valueTx}
            onChange={handleChange}
            margin="normal"
            style={{ verticalAlign: 'unset', padding: '3px' }}
          />
          <TextField
              label="Possible Values"
              name="possibleValuesTx"
              value={newConstant.possibleValuesTx}
              onChange={handleChange}
              margin="normal"
              style={{ verticalAlign: 'unset', padding: '3px' }}
          />
          <Button variant="contained" color="primary" style={{ marginLeft: '3px', backgroundColor:"#0d4a8a"}} onClick={() => handleSave(newConstant)}>
            Save
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: '3px', backgroundColor:"#0d4a8a"}} onClick={() => handleDelete(newConstant)}>
            Delete
          </Button>
        </div>
      </Fade>
    </Modal>
  );
}
