import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles} from "@mui/styles";
import {getUrl, postCall, getConstants} from "../../utils/ApiUtils";
import {search} from "../../utils/TableUtils";
import ConstantsTable from "./ConstantsTable";
import ConstantModal from "../ConstantsPage/ConstantModal";

/**
 *
 * @param
 * @constructor
 */
const ConstantsPage = ( {constants: _constants = [], setPageTitle} ) => {
  const classes = useStyles();
    //state variables
  const [constants, setConstants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedConstant, setSelectedConstant] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  let page, max;
  const handleEdit = (constant) => {
    if(constant) {
      setSelectedConstant(constant);
      setModalOpen(true);
    }
  }

  const handleClose = () => {
    setModalOpen(false);
  }

  const handleSave = async (constant) => {
    // update constant in your data source
    if(constant) {
      await postCall(getUrl('saveConstant'), constant, false);
      prepareConstants();
    }
    setModalOpen(false);
  }
  const handleDelete = async (constant) => {
    // Ask for constant confirmation before proceeding
    const isConfirmed = window.confirm("Are you sure you want to delete this constant?");

    if (isConfirmed && constant) {
      // If confirmed, proceed with the deletion
      await postCall(getUrl('deleteConstant'), constant, false);
      prepareConstants();
    }

    setModalOpen(false);
  };

  /**
   * Retrieves constants
   */
  const prepareConstants = useCallback(async () => {
    const currentPage = (!page || page < 1) ? 1 : page;
    const currentMax = (!max || max < 1) ? 500 : max;
    getConstants(currentPage, currentMax).then(function(results){
      setConstants(results);
      setLoading(false);
    });
    // if(!max || max < 1) {max=500}
    // if(!page || page < 1) {page=1}
    // constants = await getCall(getUrl('getConstants', [page, max]), false);

  }, [page, max]);
    /**
     * ComponentDidMount: What should happen when this component is first loaded into the DOM
     */
  useEffect(() => {
    setPageTitle("Constants");
    prepareConstants();
  }, [setPageTitle, prepareConstants]);

    //Other Methods
  // const handleMaxChange = (event) => {
  //   const newMax = parseInt(event.target.value);
  //   max = newMax;
  //   page = 1; // Reset page to 1
  //   $("#page").val("1");
  //   prepareConstants();
  // }
  // const handlePageChange = (event) => {
  //   page = parseInt(event.target.value);
  //   max = parseInt($("#max").val());
  //   prepareConstants();
  // }
    return (
      <div id='base'>
        <br/>
        {/*<div className={classes.max}>*/}
        {/*  <label className={classes.label}>Max Rows</label><br/>*/}
        {/*  <input type="number"*/}
        {/*         id="max"*/}
        {/*         onChange={handleMaxChange}*/}
        {/*         placeholder="Max rows per page.."*/}
        {/*         title="Max number of rows."/>*/}
        {/*</div>*/}
        {/*<div className={classes.page}>*/}
        {/*  <label className={classes.label}>Page</label><br/>*/}
        {/*  <input type="number"*/}
        {/*         id="page"*/}
        {/*         onChange={handlePageChange}*/}
        {/*         placeholder="Page number.."*/}
        {/*         title="Page number."/>*/}
        {/*</div>*/}
        <div className={classes.search}>
          <label className={classes.label}>Search Page</label><br/>
          <input type="text"
            id="search"
            onKeyUp={() => search('#constants', '#search')}
            placeholder="Search list.."
            title="Type to search."/>
        </div>
        {!loading && <ConstantsTable constants={constants} onEdit={handleEdit} />}
        {modalOpen && !loading &&
            <ConstantModal open={modalOpen} constant={selectedConstant || {}} handleClose={handleClose} handleSave={handleSave} handleDelete={handleDelete}/>}
      </div>
    );
};

export default ConstantsPage;

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'absolute',
    left: 10,
    top: 55,
    borderColor: 'gray'
  },
  max: {
    position: 'absolute',
    left: 10,
    top: 55,
    borderColor: 'gray'
  },
  page: {
    position: 'absolute',
    left: 170,
    top: 55,
    borderColor: 'gray'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '500',
    lineHeight: '1.5rem',
    fontSize: '0.875rem'
  }
}));
