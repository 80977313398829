import {getAccessToken} from "./Authentication";

/**
 * This is where we manage our api calls,
 * ALL API calls should come through this class
 * @param props
 * @constructor
 */
//URL for development on localhost
    let api_host = ""; //process.env.REACT_APP_API_GATEWAY;

export const restApiPrefix = "https://" + window.location.hostname + api_host;
export const constantMap = {};

//URL to connect to the gateway
// let gateway = restApiPrefix + "/gateway";//process.env.REACT_APP_API_GATEWAY;

export const urls = {
    'login': restApiPrefix + '/login',
    'logout': restApiPrefix + '/logout',
    'register': restApiPrefix + '/register',
    'updatePassword': restApiPrefix + '/update-password',
    'forgotPassword': restApiPrefix + '/forgot-password',
    'resetPassword': restApiPrefix + '/reset-password',
    'getUserProfile': restApiPrefix + '/profile/',
    'getUserProfiles': restApiPrefix + '/profiles',
    'getLogins': [restApiPrefix + '/login-log/', '/'],
    'updateUser': [restApiPrefix + '/update-user'],
    'deleteUser': [restApiPrefix + '/delete-user'],
    'solutions': restApiPrefix + '/solutions',
    'adminSolutions': restApiPrefix + '/admin-solutions',
    'gatewayTitle': restApiPrefix + '/gateway-title',
    'getConstants': restApiPrefix + '/constants',
    'saveConstant': restApiPrefix + '/constant',
    'deleteConstant': restApiPrefix + '/delete-constant',
    'datatablePartialRecords': restApiPrefix + '/datatable-service/records/partial',
    'getServiceVersion': restApiPrefix + '/version'
    // 'characterTraits': restApiPrefix + '/character-traits',
    // 'contentLibrary': restApiPrefix + '/content-library'
};

export const getUrl = (urlName, args = []) => {
    let urlPieces = urls[urlName];
    let url = "";
    if(urlPieces instanceof Array && urlPieces.length === args.length)
        urlPieces.forEach( (piece, i) => url+= piece + args[i]);
    else if(!(urlPieces instanceof Array) && args.length === 1)
        url += urlPieces + args[0];
    else
        url += urlPieces;
    return url;
};

/**
 * Makes a get request
 * @param urls
 * @returns {Promise<Response | never>}
 */
export const getCall = async (url, allAccess, addHeaders) => {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getAccessToken(),
        'X-Content-Type-Options': 'nosniff',
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate, br'
    };
    if(allAccess)
        delete headers['Authorization'];
    if(addHeaders){
        Object.assign(headers, addHeaders);
    }
    return await fetch(url, {
        headers: headers
    })
        .then(
            function(response) {
                return response.json();
            }
        )
        .catch(function(err) {
            if(err.message.includes('Unexpected end of JSON input'))
                console.log('No response');
            else {
                console.error("unable to retrieve the data because of: " + err);
                throw err;
            }
        });
};

/**
 * Makes a post request
 * @param url
 * @param requestBody
 * @returns {Promise<Response>}
 */
export const postCall = async (url, requestBody, allAccess, form, addHeaders) => {
    let headers = {'Content-Type': form ? 'application/x-www-form-urlencoded' : 'application/json', 'Authorization': 'Bearer ' + getAccessToken()};
    if(allAccess)
        delete headers['Authorization'];
    if(addHeaders){
        Object.assign(headers, addHeaders);
    }

    return await fetch(url, {
        method: 'POST',
        headers: headers,
        body: form ? requestBody : JSON.stringify(requestBody)
    })
        .then(response => {
            if (!response.ok) throw new Error(response.status);
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json().then(data => data);
            } else {
                return response.text().then(text => text);
            }
        })
        .catch(error => {
            throw error;
        });
};

export const getConstants = async (page, max) => {
    const constants = await getCall(getUrl('getConstants', [page, max]), false);
    for(const constant of constants){
        constantMap[constant.titleVc] = constant.valueTx;
    }
    return constants;
};
export const fetchEntities = async (email) => {
    const requestBody = {objectType: "Entity", fields: ["entityName"]}
    return await postCall(getUrl('datatablePartialRecords'), requestBody, false);
};
export const fetchServiceVersion = async () => {
    const version = await getCall(getUrl('getServiceVersion'), false);
    return version;
}