import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import PasswordModal from "../AccountPage/PasswordModal";
import './Account.css';



/**
 *
 * @param
 * @constructor
 */
const AccountPage = ({setPageTitle, user}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  //state variables

  //useEffects(Lifecycle Methods)
  /**
   * ComponentDidMount: What should happen when this component is first loaded into the DOM
   */
  useEffect(() => {
      setPageTitle("User Account");
      setLoading(false);
  }, [setPageTitle]);

  //Other Methods
  if (loading) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

    const handleEdit = (user) => {
        if(user) {
            setModalOpen(true);
        }
    }

    const afterSave = async () => {
        setModalOpen(false);
    }
    const handleClose = () => {
        setModalOpen(false);
    }
  return (
    <div id='base' className={classes.base}>
        <h3 style={{textAlign:"center"}}>Welcome,  {user?.firstName} {user?.lastName}</h3>
        <br/>
        <h5>First Name: {user?.firstName}</h5>
        <h5>Last Name: {user?.lastName}</h5>
        <h5>Email: {user?.username}</h5>
        <br/>
      <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => handleEdit(user)}
          style={{ backgroundColor:"#0d4a8a"}}
      >
        Change Password
      </Button>
        {modalOpen && !loading &&
            <PasswordModal open={modalOpen} email={user?.username} handleClose={handleClose} afterSave={afterSave} />}

    </div>
  );
};

export default AccountPage;

const useStyles = makeStyles((theme) => ({
  base: {
    margin: '33px',
    marginTop: '60px'
  },
  biggerLink: {
    fontSize: '20px'
  }

}));
