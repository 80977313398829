import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import './About.css';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function AboutModal({open, onClose, uiVersion, serviceVersion, databaseVersion}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className="AboutModal">
          <h2>About</h2>
          <Box sx={{ ...modalStyles }}>
            <Typography variant="h6">About</Typography>
            <Typography>Gateway UI Version: {uiVersion}</Typography>
            <Typography>Gateway Service Version: {serviceVersion}</Typography>
            <Typography>Gateway Database Version: {databaseVersion}</Typography>
            <br />
            <Button variant="contained" color="primary" style={{ backgroundColor:"#0d4a8a" }} onClick={() => onClose()}>
              Close
            </Button>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
