import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import Container from '@mui/material/Container';
import {register as signUp} from '../../utils/Authentication';
import {useNavigate} from "react-router";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {fetchEntities} from "../../utils/ApiUtils";
import EnhancedSelect from "../../components/EnhancedSelect";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Gateway
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: '1px',
        backgroundColor: '#f50057',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: '3px',
    },
    submit: {
        margin: '3px,0,2px',
    },
}));

export default function SignUp({setPageTitle, ...props}) {
    const classes = useStyles();
    const [newUser, setNewUser] = useState({});
    const [entities, setEntities] = useState([]);
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [entityUuid, setEntityUuid] = useState('');
    // const [roles, setRoles] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setPageTitle('Setup User');
        fetchEntityList();
    }, [setPageTitle]);
    const handleChange = (event) => {
        setNewUser({...newUser, [event.target.name]: event.target.value});
    }
    const fetchEntityList = async () => {
        let records = await fetchEntities();
        setEntities(records);
        setLoading(false);
    }
    const register = (event) => {
        event.preventDefault();
        if (newUser.password === confirmPassword) {
            signUp(newUser).then((result) => {
                navigate('/users');
                //props.setLoggedIn(true);
            }).catch(e => {
                console.log(e);
            });
        } else {
            alert("Passwords do not match!");
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <br/>
                {/*<Avatar className={classes.avatar}>*/}
                {/*  <LockOutlinedIcon />*/}
                {/*</Avatar>*/}
                <br/>
                {/*<Typography component="h1" variant="h5">*/}
                {/*  User Setup*/}
                {/*</Typography>*/}
                <form className={classes.form} noValidate autoComplete="false">
                    <input type="text" autoComplete="false" name="hidden" style={{display:'none'}}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                onChange={e => handleChange(e)}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                onChange={e => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address [case sensitive]"
                                name="username"
                                type="text"
                                onChange={e => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                onChange={e => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                onChange={e => setConfirmPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="roles"
                                label="Roles [case sensitive]"
                                id="roles"
                                onChange={e => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {!loading &&
                                <EnhancedSelect
                                    entities={entities}
                                    newUser={newUser}
                                    setNewUser={setNewUser}
                                ></EnhancedSelect>}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={register}
                            >
                                Setup User
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright/>
            </Box>
        </Container>
    );
}
