import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import {getCall, restApiPrefix, urls} from "../../utils/ApiUtils";


/**
 *
 * @param
 * @constructor
 */
const AdminPage = ({setPageTitle}) => {
    const classes = useStyles();
    const [solutions, setSolutions] = useState(null);
    const [loading, setLoading] = useState(true);
    //state variables

    //useEffects(Lifecycle Methods)
    /**
     * ComponentDidMount: What should happen when this component is first loaded into the DOM
     */
    useEffect(() => {
      setPageTitle("Admin Page");
        const fetchAdminSolutions = async () => {
            if (!solutions) {
                try {
                    const result = await getCall(urls.adminSolutions);
                    console.log(result);
                    setSolutions(result)
                } catch (error) {
                    console.error("Error fetching solutions:", error);
                }
            }
            setLoading(false); // Set loading to false regardless of whether data was fetched
        };
        fetchAdminSolutions();
    }, [setPageTitle, solutions]);

    //Other Methods
    if (loading) {
        return <div>Loading...</div>; // Show a loading state while data is being fetched
    }

    return (
      <div id='base' className={classes.base}>
        <br></br>
        <div><Link to="/users" className={`${classes.biggerLink}`} style={{ textDecoration: 'none' }}>Users</Link></div>
        <br></br>
        <div><Link to="/logins" className={`${classes.biggerLink}`} style={{ textDecoration: 'none' }}>Login Log</Link></div>
          <br></br>
          <div><Link to="/constants" className={`${classes.biggerLink}`} style={{ textDecoration: 'none' }}>Constants</Link></div>
          {solutions.map((solution) => (
              <div><br/><Link to={`${restApiPrefix + solution.url}`} className={`${classes.biggerLink}`} style={{ textDecoration: 'none' }}>{solution.title}</Link></div>
          ))}
      </div>
    );
};

export default AdminPage;

const useStyles = makeStyles((theme) => ({
    base: {
        margin: '33px'
    },
    biggerLink: {
      fontSize: '20px',
      fontWeight: 'bolder',
      color: '#053b71'
    }

}));
