import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Account.css';
import {updatePassword as changePassword} from "../../utils/Authentication";

export default function PasswordModal({ email, open, handleClose, afterSave}) {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSave = () => {
    if(email && oldPassword && password && confirmPassword && password === confirmPassword) {
      changePassword(email, oldPassword, password).catch(e => {
        console.log(e);
      });
      afterSave();
    } else {
      alert("Passwords do not match!");
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="PasswordModal">
          <h2>Change Password</h2>
          <TextField
              required
              name="oldPassword"
              label="Old Password"
              type="password"
              style={{ verticalAlign: 'unset', padding: '3px' }}
              id="oldPassword"
              onChange={e => setOldPassword(e.target.value)}
          />
          <TextField
              required
              name="password"
              label="New Password"
              type="password"
              style={{ verticalAlign: 'unset', padding: '3px' }}
              id="password"
              onChange={e => setPassword(e.target.value)}
          />
          <TextField
              required
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              style={{ verticalAlign: 'unset', padding: '3px' }}
              id="confirmPassword"
              onChange={e => setConfirmPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" style={{ marginLeft: '3px', backgroundColor:"#0d4a8a"}} onClick={() => handleSave()}>
            Save
          </Button>
        </div>
      </Fade>
    </Modal>
  );
}
