import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from "react";
import Button from "@mui/material/Button";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '39px',
    overflowX: 'auto',
    maxHeight: '-webkit-fill-available'
  },
  table: {
    minWidth: 650,
  },
  thead: {
    '& .MuiTableCell-head': {  // This targets TableCell components inside TableHead
      fontWeight: 600         // Set the font weight to 600
    }
  },
  button: {
    margin: '1px',
  },
}));

export default function ConstantsTable({ constants, onEdit }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table id='constants' className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell><Button
                variant="contained"
                color="primary"
                className={classes.button}
                style={{ backgroundColor:"#777c82", padding:"unset"}}
                onClick={() => onEdit({})}>Add</Button></TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Possible Values</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {constants.map(constant => (
              <TableRow key={constant.uuid}>
                <TableCell>
                  <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      style={{ backgroundColor:"#0d4a8a", padding:"unset"}}
                      onClick={() => onEdit(constant)}>Edit</Button>
                </TableCell>
              <TableCell>{constant.titleVc}</TableCell>
              <TableCell>{constant.descriptionTx}</TableCell>
              <TableCell>{`${constant.valueTx}`}</TableCell>
                <TableCell>{`${constant.possibleValuesTx}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
