import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles} from "@mui/styles";
import {getCall, getUrl} from "../../utils/ApiUtils";
import {search} from "../../utils/TableUtils";
import LoginsTable from "./LoginsTable";
// import $ from 'jquery';

/**
 *
 * @param
 * @constructor
 */
const LoginsPage = ( {logins: _logins = [], setPageTitle} ) => {
  const classes = useStyles();
    //state variables
  const [logins, setLogins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [max, setMax] = useState(500);

  const getLogins = useCallback(async () => {
    const currentPage = page < 1 ? 1 : page;
    const currentMax = max < 1 ? 500 : max;
    const fetchedLogins = await getCall(getUrl('getLogins', [currentPage, currentMax]), false);
    setLogins(fetchedLogins);
    setLoading(false);
  }, [page, max]);

  useEffect(() => {
    setPageTitle("Login Log");
    getLogins();
  }, [setPageTitle, getLogins]);

  const handleMaxChange = (event) => {
    const newMax = parseInt(event.target.value);
    setMax(newMax);
    setPage(1); // Reset page to 1
  };

  const handlePageChange = (event) => {
    setPage(parseInt(event.target.value));
  };
  // let page, max;
  //
  // /**
  //  * Retrieves login logs
  //  */
  // const getLogins = useCallback(async () => {
  //   let logins;
  //   const currentPage = (!page || page < 1) ? 1 : page;
  //   const currentMax = (!max || max < 1) ? 500 : max;
  //   logins = await getCall(getUrl('getLogins', [currentPage, currentMax]), false);
  //   // if(!max || max < 1) {max=500}
  //   // if(!page || page < 1) {page=1}
  //   // logins = await getCall(getUrl('getLogins', [page, max]), false);
  //
  //   setLogins(logins)
  //   setLoading(false);
  // }, [page, max]);
  //   /**
  //    * ComponentDidMount: What should happen when this component is first loaded into the DOM
  //    */
  // useEffect(() => {
  //   setPageTitle("Login Log");
  //   getLogins();
  // }, [setPageTitle, getLogins]);
  //
  //   //Other Methods
  // const handleMaxChange = (event) => {
  //   const newMax = parseInt(event.target.value);
  //   max = newMax;
  //   page = 1; // Reset page to 1
  //   $("#page").val("1");
  //   getLogins();
  // }
  // const handlePageChange = (event) => {
  //   page = parseInt(event.target.value);
  //   max = parseInt($("#max").val());
  //   getLogins();
  // }
    return (
      <div id='base'>
        <br/>
        <div className={classes.max}>
          <label className={classes.label}>Max Rows</label><br/>
          <input type="number"
                 id="max"
                 onChange={handleMaxChange}
                 placeholder="Max rows per page.."
                 title="Max number of rows."
                 value={max}
          />

        </div>
        <div className={classes.page}>
          <label className={classes.label}>Page</label><br/>
          <input type="number"
                 id="page"
                 onChange={handlePageChange}
                 placeholder="Page number.."
                 title="Page number."
                 value={page}
          />
        </div>
        <div className={classes.search}>
          <label className={classes.label}>Search Page</label><br/>
          <input type="text"
            id="search"
            onKeyUp={() => search('#logins', '#search')}
            placeholder="Search list.."
            title="Type to search."/>
        </div>
        {!loading && <LoginsTable logins={logins} />}
      </div>
    );
};

export default LoginsPage;

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'absolute',
    left: 330,
    top: 55,
    borderColor: 'gray'
  },
  max: {
    position: 'absolute',
    left: 10,
    top: 55,
    borderColor: 'gray'
  },
  page: {
    position: 'absolute',
    left: 170,
    top: 55,
    borderColor: 'gray'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '500',
    lineHeight: '1.5rem',
    fontSize: '0.875rem'
  }
}));
