import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import React from "react";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '39px',
    overflowX: 'auto',
    maxHeight: '-webkit-fill-available'
  },
  table: {
    minWidth: 650,
  },
  thead: {
    '& .MuiTableCell-head': {  // This targets TableCell components inside TableHead
      fontWeight: 600         // Set the font weight to 600
    }
  },
  button: {
    margin: '1px',
  },
}));

export default function UsersTable({ users, onEdit }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table id='users' className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                style={{ backgroundColor:"#777c82", padding:"unset"}}
                component={Link}
                to="/register"
            >
              Add
            </Button></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Roles</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <TableRow key={user.uuid}>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => onEdit(user)}
                  style={{ backgroundColor:"#0d4a8a"}}
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>{user.firstName + " " + user.lastName}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.status}</TableCell>
              <TableCell>{user.roles.join()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
