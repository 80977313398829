import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

function EnhancedSelect({ entities, newUser, setNewUser }) {
    const handleChange = (event, value) => {
        // Assuming you want to store the selected entity's UUID
        setNewUser(prevState => ({ ...prevState, entityUuid: value.uuid }));
    };

    return (
        <Autocomplete
            value={entities.find(entity => entity.uuid === newUser.entityUuid) || null}
            onChange={handleChange}
            options={entities}
            getOptionLabel={(option) => option.entityName}
            renderInput={(params) => (
                <TextField {...params} label="Entity" variant="outlined" />
            )}
            fullWidth
        />
    );
}

export default EnhancedSelect;
