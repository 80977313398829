import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '39px',
    overflowX: 'auto',
    maxHeight: '-webkit-fill-available'
  },
  table: {
    minWidth: 650
  },
  thead: {
    '& .MuiTableCell-head': {  // This targets TableCell components inside TableHead
      fontWeight: 600         // Set the font weight to 600
    }
  },
  button: {
    margin: '1px'
  },
}));

export default function LoginsTable({ logins }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table id='logins' className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Login Time</TableCell>
            <TableCell>Login Successful</TableCell>
            <TableCell>Logout Time</TableCell>
            <TableCell>User Agent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logins.map(login => (
            <TableRow>
              <TableCell>{login.usernameVc}</TableCell>
              <TableCell>{login.loginTime}</TableCell>
              <TableCell>{`${login.loginSuccessfulTf}`}</TableCell>
              <TableCell>{login.logoutTime}</TableCell>
              <TableCell>{login.userAgentTx}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
