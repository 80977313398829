import {useEffect} from 'react';
import {useNavigate} from "react-router";

import {logout} from "../../utils/Authentication";


export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("----hit logout page useEffect----");
    const performLogout = () => {
      logout();
      navigate('/');
    };

    performLogout();
  }, [navigate]);

  return null;
}
