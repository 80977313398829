import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import './User.css';
import MenuItem from "@mui/material/MenuItem";
import EnhancedSelect from "../../components/EnhancedSelect";

export default function UserModal({ entities, user, open, handleClose, handleSave, handleDelete }) {
  const [newUser, setNewUser] = useState(user);
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false)

  const handleChange = (event) => {
    setNewUser({ ...newUser, [event.target.name]: event.target.value });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="UserModal">
          <h2>Edit User</h2>
          <TextField
            label="First Name"
            name="firstName"
            value={newUser.firstName}
            onChange={handleChange}
            margin="normal"
            style={{ verticalAlign: 'unset', padding: '3px',...(changePassword ? { display: 'none' } : {}) }}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={newUser.lastName}
            onChange={handleChange}
            margin="normal"
            style={{ verticalAlign: 'unset', padding: '3px',...(changePassword ? { display: 'none' } : {}) }}
          />
          <TextField
            label="Email"
            name="username"
            value={newUser.username}
            onChange={handleChange}
            margin="normal"
            style={{ verticalAlign: 'unset', padding: '3px',...(changePassword ? { display: 'none' } : {}) }}
          />
          <TextField
              required
              name="password"
              value={password}
              label="Change Password"
              type="password"
              style={{ verticalAlign: 'unset', padding: '3px' ,...(changePassword ? {} : { display: 'none' })}}
              id="password"
              onChange={e => setPassword(e.target.value)}
          />
          <TextField
            label="Status"
            name="status"
            value={newUser.status}
            onChange={handleChange}
            margin="normal"
            style={{ verticalAlign: 'unset', padding: '3px',...(changePassword ? { display: 'none' } : {}) }}
          />
          <TextField
            label="Roles"
            name="roles"
            value={newUser.roles}
            onChange={handleChange}
            margin="normal"
            style={{ verticalAlign: 'baseline', padding: '3px',...(changePassword ? { display: 'none' } : {}) }}
          />
          {/*<Select*/}
          {/*    name={"entityUuid"}*/}
          {/*    value={newUser.entityUuid}*/}
          {/*    label="Entity"*/}
          {/*    onChange={handleChange}*/}
          {/*    margin="normal"*/}
          {/*    style={{ verticalAlign: 'baseline', padding: '3px',...(changePassword ? { display: 'none' } : {}) }}*/}
          {/*>*/}
          {/*  {entities.map((entity) => (*/}
          {/*      <MenuItem key={entity.uuid} value={entity.uuid}>*/}
          {/*        {entity.record.entityName}*/}
          {/*      </MenuItem>*/}
          {/*  ))}*/}
          {/*</Select>*/}
          <EnhancedSelect
            entities={entities}
            newUser={newUser}
            setNewUser={setNewUser}
            ></EnhancedSelect>
          <Button variant="contained" color="primary" style={{ marginLeft: '6px', marginTop: '8px', backgroundColor:"#0d4a8a"}} onClick={() => handleSave(newUser, password, changePassword)}>
            Save
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: '6px', marginTop: '8px', backgroundColor:"#0d4a8a",...(changePassword ? { display: 'none' } : {})}} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: '6px', marginTop: '8px', backgroundColor:"#0d4a8a",...(changePassword ? { display: 'none' } : {})}} onClick={() => handleDelete(newUser)}>
            Delete User
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: '6px', marginTop: '8px', backgroundColor:"#0d4a8a",...(changePassword ? { display: 'none' } : {})}} onClick={() => setChangePassword(true)}>
            Change Password
          </Button>
        </div>
      </Fade>
    </Modal>
  );
}
