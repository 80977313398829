import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import {getCall, urls, restApiPrefix} from "../../utils/ApiUtils";
// import {isAuthenticated} from "../../utils/Authentication";



/**
 *
 * @param
 * @constructor
 */
const HomePage = ({setPageTitle}) => {
  const classes = useStyles();
  const [solutions, setSolutions] = useState(null);
  const [loading, setLoading] = useState(true);
  //state variables

  //useEffects(Lifecycle Methods)
  /**
   * ComponentDidMount: What should happen when this component is first loaded into the DOM
   */
  useEffect(() => {
    setPageTitle("Home Page");
    const fetchSolutions = async () => {
      if (!solutions) {
        try {
          const result = await getCall(urls.solutions);
          console.log(result);
          setSolutions(result)
        } catch (error) {
          console.error("Error fetching solutions:", error);
        }
      }
      setLoading(false); // Set loading to false regardless of whether data was fetched
    };
    fetchSolutions();
  }, [setPageTitle, solutions]);

  //Other Methods
  if (loading) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

  return (
    <div id='base' className={classes.base}>
      {solutions.map((solution) => (
        <div><br/><Link to={`${restApiPrefix + solution.url}`} className={`${classes.biggerLink}`} style={{ textDecoration: 'none' }}>{solution.title}</Link></div>
      ))}
    </div>
  );
};

export default HomePage;

const useStyles = makeStyles((theme) => ({
  base: {
    margin: '33px',
  },
  biggerLink: {
    fontSize: '20px',
    fontWeight: 'bolder',
    color: '#053b71'
  }

}));
