function Header({ pageTitle }) {
  return (
    <div className="header">
      <h1>{pageTitle}</h1>
      {/* other header content */}
    </div>
  );
}

export default Header;
