export const search = (table, searchElement) => {
  const trs = document.querySelectorAll(`${table} > tbody > tr:not(thead > tr, .detail)`);
  const filter = document.querySelector(`${searchElement}`).value;
  const regex = new RegExp(filter, 'i');

  const getTextContent = (element) => {
    let text = '';
    if (element.nodeType === Node.TEXT_NODE) {
      text += element.textContent.trim();
    } else if (element.nodeType === Node.ELEMENT_NODE) {
      const childNodes = element.childNodes;
      for (let i = 0; i < childNodes.length; i++) {
        text += getTextContent(childNodes[i]);
      }
    }
    return text;
  };

  const isFoundInTds = (td) => {
    const tdText = getTextContent(td);
    return regex.test(tdText);
  };

  const isFound = (childrenArr) => childrenArr.some(isFoundInTds);

  const setTrStyleDisplay = ({ style, children }) => {
    style.display = isFound([...children]) ? '' : 'none';
  };

  trs.forEach(setTrStyleDisplay);
};
