import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import Container from '@mui/material/Container';
import {useNavigate, useLocation} from "react-router";
import {login as userLogin, isAuthenticated} from "../../utils/Authentication";
// import {red} from "@mui/material/colors";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Gateway
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '8px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '8px',
  },
  // submit: {
  //   margin: '24px 0 16px',
  // },
}));

export default function SignIn({setPageTitle, setLoggedIn, setUserProfile, ...props}) {
  const classes = useStyles();

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {location} = useLocation();
  const navigate = useNavigate();


  const login = async (event) => {
    event.preventDefault();
    await userLogin(email, password).then(() => {
      setRedirectToReferrer(true);
      setLoggedIn(true);
      setUserProfile();
    }).catch((err) => {
      console.error(err);
    });
  };

  useEffect(() => {
    setPageTitle("Login");
  }, [setPageTitle]);

  useEffect(() => {
    console.log("----hit login page useEffect---- authenticated:" + isAuthenticated());
    if (isAuthenticated()) {
      navigate(location?.from || '/', { replace: true });
    }
  });

  useEffect(() => {
    if (redirectToReferrer === true) {
      navigate(location?.from || "/", { replace: true });
    }
  }, [redirectToReferrer, location, navigate]);


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <br/>
        <Avatar sx={{ backgroundColor: "#f50057" }}>
          <LockOutlinedIcon/>
        </Avatar>
        <br/>
        {/*<Typography component="h1" variant="h5" sx={{ margin: "8px"}}>*/}
        {/*  Sign in*/}
        {/*</Typography>*/}
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="username"
            autoComplete="username"
            autoFocus
            value={email}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              // autoComplete="password"
              value={password}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setPassword(e.target.value)}

          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary"/>}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={login}
            sx={{
              marginTop:6,
              backgroundColor: "#3f51b5"
            }}
          >
            Sign In
          </Button>
          {/*<Grid container>*/}
          {/*    <Grid item xs>*/}
          {/*        <RLink to="/forgot-password" variant="body2">*/}
          {/*            Forgot password?*/}
          {/*        </RLink>*/}
          {/*    </Grid>*/}
          {/*    <Grid item>*/}
          {/*        /!*<Link href="/register" variant="body2">*!/*/}
          {/*        /!*    {"Don't have an account? Sign Up"}*!/*/}
          {/*        /!*</Link>*!/*/}
          {/*      <RLink to="/register">*/}
          {/*        {"Don't have an account? Sign Up"}*/}
          {/*      </RLink>*/}
          {/*    </Grid>*/}
          {/*</Grid>*/}
        </form>
      </div>
      <Box mt={8}>
        <Copyright/>
      </Box>
    </Container>
  );
}
